<template>
  <div
    class="views_shopping_mail_featured_common_title d-flex align-center justify-space-between"
  >
    <div class="title_group d-flex align-center">
      <div class="title">{{title}}</div>
      <div class="subtitle pl-12"
        v-if="sub_title"
      >{{sub_title}}</div>
    </div>
    <div class="more_group">
      <div class="button more cp"
        v-if="show_more"
        @click.stop="$emit('handleMore')"
      >更多></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined,
    },
    sub_title: {
      type: String,
      default: undefined,
    },
    show_more: {
      type: Boolean,
      default: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_featured_common_title{
  .title{
    font-size: 22px;
    color: #000000;
  }
  .subtitle{
    font-size: 14px;
    color: $main_blue;
  }
  .more{
    @include transition;
    font-size: 16px;
    color: #999;
    &:hover{
      color: $main_blue;
    }
  }
}
</style>