var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_featured_common_title d-flex align-center justify-space-between",
    },
    [
      _c("div", { staticClass: "title_group d-flex align-center" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm.sub_title
          ? _c("div", { staticClass: "subtitle pl-12" }, [
              _vm._v(_vm._s(_vm.sub_title)),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "more_group" }, [
        _vm.show_more
          ? _c(
              "div",
              {
                staticClass: "button more cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$emit("handleMore")
                  },
                },
              },
              [_vm._v("更多>")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }